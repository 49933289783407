import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Footer from "../components/footer"

const ContactoPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          datoCmsPaginaContactoMle {
            titulo
            contenido
          }
        }
      `}
      render={data => (
        <Layout pageTitle="Contacto">
          <div className="w-full mx-auto  bg-gray-200 border-b border-gray-200">
            <div className="w-full lg:w-10/12 mx-auto">
              <Navigation />
            </div>
          </div>

          <div className="w-full px-5 lg:px-0 lg:w-10/12 mx-auto">
            <div className="w-full mx-auto mt-10 lg:mt-20">
              <h1 className="text-3xl">
                {data.datoCmsPaginaContactoMle.titulo}
              </h1>

              <div
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsPaginaContactoMle.contenido,
                }}
              />
            </div>
          </div>

          <Footer />
        </Layout>
      )}
    />
  )
}

export default ContactoPage
